import { AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector, useColorMode } from "../hooks";
import { logout, selectIdentity } from "../user/slice";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { setColorMode } from "./slice";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const identity = useAppSelector(selectIdentity);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
    undefined
  );
  const mode = useColorMode();

  if (!identity) {
    return null;
  }

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const signOut = () => {
    dispatch(logout());
    closeMenu();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
        borderBottomWidth: 5,
        borderBottomColor: (theme) => theme.palette.secondary.main,
        borderBottomStyle: "solid",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link href="https://radoku.com" underline="none" color="primary">
            Radoku
          </Link>
        </Typography>
        <Box>
          <IconButton
            size="large"
            aria-label="toggle color mode"
            color="inherit"
            onClick={() => {
              if (mode === "dark") {
                dispatch(setColorMode("light"));
              } else {
                dispatch(setColorMode("dark"));
              }
            }}
          >
            {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={openMenu}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={anchorEl !== undefined}
            onClose={closeMenu}
          >
            <MenuItem component={RouterLink} to="/settings">
              Settings
            </MenuItem>
            <MenuItem onClick={signOut}>Sign out</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
