import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface State {
  colorMode: PaletteMode | undefined;
}

const initialState: State = {
  colorMode: undefined,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setColorMode: (state, { payload: mode }: PayloadAction<PaletteMode>) => {
      state.colorMode = mode;
    },
  },
});

export const { setColorMode } = mainSlice.actions;

export const selectColorMode = (state: RootState) => state.main.colorMode;
