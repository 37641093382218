import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectIdentity } from "./slice";

const RequireUser = ({ children }: { children: ReactNode }) => {
  const identity = useAppSelector(selectIdentity);

  if (!identity) {
    const query = new URLSearchParams({
      return_to: window.location.href,
    }).toString();
    return <Navigate to={`/login?${query}`} />;
  }

  const verified = (identity.verifiable_addresses ?? []).some(
    (address) => address.verified
  );

  if (!verified) {
    return <Navigate to="/verification_required" />;
  }

  return <div>{children}</div>;
};

export default RequireUser;
