import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import KeyIcon from "@mui/icons-material/Key";
import QueryStats from "@mui/icons-material/QueryStats";
import { Link } from "react-router-dom";

const SideDrawer = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 200,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 200,
          boxSizing: "border-box",
          borderRightWidth: 5,
          borderRightColor: (theme) => theme.palette.primary.main,
          borderRightStyle: "solid",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          <ListItemButton component={Link} to="/usage">
            <ListItemIcon>
              <QueryStats color="primary" />
            </ListItemIcon>
            <ListItemText primary="Usage" />
          </ListItemButton>

          <ListItemButton component={Link} to="/tokens">
            <ListItemIcon>
              <KeyIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Tokens" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
