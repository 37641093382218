import { Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Main from "./Main";

const VerificationRequired = () => {
  return (
    <Main hideDrawer>
      <Container
        maxWidth="sm"
        sx={{
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Verify your Radoku account</Typography>
        <Typography sx={{ mt: 2 }}>
          Please verify your email address. Verification link has been sent to
          the email address you used to create the account.
        </Typography>
        <Typography sx={{ mt: 2 }}></Typography>
        <Typography>
          {"Didn't receive the email? "}
          <Link component={RouterLink} to="/verification">
            Request a new verification email
          </Link>
        </Typography>
      </Container>
    </Main>
  );
};

export default VerificationRequired;
