import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FlowUi from "./FlowUi";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getOrCreateSettingsFlow,
  selectSettingsFlow,
  selectSettingsFlowError,
  submitSettingsFlow,
} from "./slice";
import { SubmitSelfServiceSettingsFlowBody } from "@ory/kratos-client";
import Main from "../main/Main";
import handleFlowError from "./handleFlowError";

const Settings = () => {
  const [query, setQuery] = useSearchParams();
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectSettingsFlow);
  const flowError = useAppSelector(selectSettingsFlowError);
  const flowId = query.get("flow");
  const returnTo = query.get("return_to");
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(getOrCreateSettingsFlow({ flowId, returnTo }));
  }, []);

  useEffect(() => {
    const newQuery = new URLSearchParams();

    if (returnTo) {
      newQuery.set("return_to", returnTo);
    }

    setQuery(newQuery, { replace: true });
  }, [flow]);

  useEffect(() => {
    handleFlowError({
      resetFlow: () =>
        dispatch(getOrCreateSettingsFlow({ flowId: null, returnTo })),
      setError,
      error: flowError,
    });
  }, [flowError]);

  const onSubmit = async (data: unknown) => {
    if (!flow) {
      return;
    }

    setError(undefined);

    const newQuery = new URLSearchParams();
    newQuery.set("flow", flow.id);
    setQuery(newQuery, { replace: true });

    try {
      await dispatch(
        submitSettingsFlow({
          flowId: flow.id,
          body: data as SubmitSelfServiceSettingsFlowBody,
        })
      ).unwrap();
    } catch {
      // await is just for progress and error is handled in redux
    }
  };

  return (
    <Main>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {flow?.ui.messages &&
        flow.ui.messages.map((message) => (
          <Alert
            key={message.id}
            severity={message.type as AlertColor}
            sx={{ mb: 2 }}
          >
            {message.text}
          </Alert>
        ))}

      {flow ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
          <Grid item xs={12} lg={6}>
            <Paper elevation={4} sx={{ p: 2, flex: 1 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Profile settings
              </Typography>

              <FlowUi
                type="settings"
                ui={flow.ui}
                filterGroup="profile"
                onSubmit={onSubmit}
                hideGlobalMessage
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper elevation={4} sx={{ p: 2, flex: 1 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Change password
              </Typography>

              <FlowUi
                type="settings"
                ui={flow.ui}
                filterGroup="password"
                onSubmit={onSubmit}
                hideGlobalMessage
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper elevation={4} sx={{ p: 2, flex: 1 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Manage linked accounts
              </Typography>

              <FlowUi
                type="settings"
                ui={flow.ui}
                filterGroup="oidc"
                onSubmit={onSubmit}
                hideGlobalMessage
              />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Main>
  );
};

export default Settings;
