import "@fontsource/archivo";
import "react-toastify/dist/ReactToastify.css";

import React, { useMemo } from "react";
import { createTheme, CssBaseline, Theme, ThemeProvider } from "@mui/material";
import Router from "./Router";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import styled from "@emotion/styled";
import { useColorMode } from "./hooks";

const StyledToastContainer = styled<
  React.FC<ToastContainerProps & { muiTheme: Theme }>
>(ToastContainer)`
  --toastify-color-light: ${(props) =>
    props.muiTheme.palette.background.default};
  --toastify-text-color-light: ${(props) =>
    props.muiTheme.palette.text.primary};
  --toastify-color-info: ${(props) => props.muiTheme.palette.info.main};
  --toastify-color-success: ${(props) => props.muiTheme.palette.success.main};
  --toastify-color-warning: ${(props) => props.muiTheme.palette.warning.main};
  --toastify-color-error: ${(props) => props.muiTheme.palette.error.main};
  --toastify-toast-width: 400px;
  --toastify-font-family: ${(props) => props.muiTheme.typography.fontFamily};
`;

const App = () => {
  const mode = useColorMode();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            light: "#ff5dbe",
            main: "#ff008e",
            dark: "#c60061",
            contrastText: "#fff",
          },
          secondary: {
            light: "#9255c8",
            main: "#612897",
            dark: "#300068",
            contrastText: "#fff",
          },
          error: {
            light: "#ff6036",
            main: "#ff1700",
            dark: "#c20000",
            contrastText: "#000",
          },
          warning: {
            light: "#ffff55",
            main: "#ffe400",
            dark: "#c7b200",
            contrastText: "#000",
          },
          success: {
            light: "#71ff56",
            main: "#06ff00",
            dark: "#00ca00",
            contrastText: "#000",
          },
          info: {
            light: "#8fbcff",
            main: "#548cff",
            dark: "#005fcb",
            contrastText: "#000",
          },
        },
        shape: {
          borderRadius: 2,
        },
        typography: {
          fontFamily: "'Archivo', 'Helvetica', 'Arial', sans-serif;",
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledToastContainer position="top-center" muiTheme={theme} />
      <Router />
    </ThemeProvider>
  );
};

export default App;
