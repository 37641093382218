import {
  CircularProgress,
  Container,
  Alert,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import {
  useCreateTokenMutation,
  useDeleteTokenMutation,
  useGetTokensQuery,
} from "./slice";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import Main from "../main/Main";

const Tokens = () => {
  const { data, error, isLoading } = useGetTokensQuery();
  const [createToken, createResult] = useCreateTokenMutation();
  const [deleteToken, deleteResult] = useDeleteTokenMutation();
  const [query, setQuery] = useSearchParams();

  useEffect(() => {
    if (isLoading || error) {
      return;
    }

    const key = query.get("key");

    if (key) {
      createToken({
        unclaimedSessionKey: key,
      });
      setQuery({}, { replace: true });
    }
  }, [isLoading, error]);

  useEffect(() => {
    if (createResult.error) {
      if (createResult.originalArgs?.unclaimedSessionKey) {
        toast.error(
          <>
            Error creating a new authentication token for session with key{" "}
            <Typography component="span" fontWeight={700}>
              {createResult.originalArgs?.unclaimedSessionKey}
            </Typography>
            .
          </>
        );
      } else {
        toast.error("Error creating a new authentication token.");
      }

      createResult.reset();
    } else if (createResult.isSuccess) {
      if (createResult.originalArgs?.unclaimedSessionKey) {
        toast.success(
          <Box>
            Authentication token{" "}
            <Typography component="span" fontWeight={700}>
              {createResult.data.value}
            </Typography>{" "}
            created for session with key{" "}
            <Typography component="span" fontWeight={700}>
              {createResult.originalArgs?.unclaimedSessionKey}
            </Typography>
            .
          </Box>
        );
      } else {
        toast.success(
          <>
            Authentication token{" "}
            <Typography component="span" fontWeight={700}>
              {createResult.data.value}
            </Typography>{" "}
            created.
          </>
        );
      }
      createResult.reset();
    }
  }, [createResult]);

  useEffect(() => {
    if (deleteResult.error) {
      toast.error("Error deleting authentication token.");
      deleteResult.reset();
    } else if (deleteResult.isSuccess) {
      toast.success("Authentication token deleted.");
      deleteResult.reset();
    }
  }, [deleteResult]);

  if (isLoading) {
    return (
      <Main>
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Container>
      </Main>
    );
  }

  if (error) {
    return (
      <Main>
        <Alert severity="error">Error fetching tokens</Alert>
      </Main>
    );
  }

  return (
    <Main>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Authentication tokens</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            createToken(null);
          }}
        >
          Create new
        </Button>
      </Box>

      <Box sx={{ my: 2 }}>
        {data && data.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="authentication tokens table">
              <TableHead>
                <TableRow>
                  <TableCell>Token</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((token) => (
                  <TableRow
                    key={token.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{token.value}</TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat(undefined, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(token.createdAt))}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          deleteToken(token);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No authentication tokens</Typography>
        )}
      </Box>
    </Main>
  );
};

export default Tokens;
