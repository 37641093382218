import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";
import SideDrawer from "./SideDrawer";
import TopBar from "./TopBar";

const Main = ({
  children,
  hideDrawer,
}: {
  children: ReactNode;
  hideDrawer?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <TopBar />
      {!hideDrawer && <SideDrawer />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Main;
