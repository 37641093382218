import {
  Alert,
  CircularProgress,
  Container,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import FlowUi from "./FlowUi";
import {
  getOrCreateLoginFlow,
  selectLoginFlow,
  selectLoginFlowError,
  submitLoginFlow,
} from "./slice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { SubmitSelfServiceLoginFlowBody } from "@ory/kratos-client";
import { Box } from "@mui/system";
import handleFlowError from "./handleFlowError";

const Login = () => {
  const [query, setQuery] = useSearchParams();
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectLoginFlow);
  const flowError = useAppSelector(selectLoginFlowError);
  const flowId = query.get("flow");
  const returnTo = query.get("return_to");
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(getOrCreateLoginFlow({ flowId, returnTo }));
  }, []);

  useEffect(() => {
    const newQuery = new URLSearchParams();

    if (returnTo) {
      newQuery.set("return_to", returnTo);
    }

    setQuery(newQuery, { replace: true });
  }, [flow]);

  useEffect(() => {
    handleFlowError({
      resetFlow: () =>
        dispatch(getOrCreateLoginFlow({ flowId: null, returnTo })),
      setError,
      error: flowError,
    });
  }, [flowError]);

  const onSubmit = async (data: unknown) => {
    if (!flow) {
      return;
    }

    setError(undefined);

    const newQuery = new URLSearchParams();
    newQuery.set("flow", flow.id);
    setQuery(newQuery, { replace: true });

    try {
      await dispatch(
        submitLoginFlow({
          flowId: flow.id,
          body: data as SubmitSelfServiceLoginFlowBody,
        })
      ).unwrap();
    } catch {
      // await is just for progress and error is handled in redux
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {flow ? (
        <Paper elevation={4} sx={{ m: 2, p: 2, flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Sign in to your account
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}

          <FlowUi
            type="login"
            filterGroup="password"
            ui={flow.ui}
            onSubmit={onSubmit}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            OR
          </Box>

          <FlowUi
            type="registration"
            filterGroup="oidc"
            ui={flow.ui}
            onSubmit={onSubmit}
            hideGlobalMessage
          />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ px: 2, pt: 1 }}>
        <Typography>
          Forgot your password?{" "}
          <Link
            component={RouterLink}
            to={{
              pathname: "/recovery",
              search: returnTo
                ? new URLSearchParams({ return_to: returnTo }).toString()
                : undefined,
            }}
          >
            Recover account
          </Link>
        </Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography>
          {"Don't have an account? "}
          <Link
            component={RouterLink}
            to={{
              pathname: "/registration",
              search: returnTo
                ? new URLSearchParams({ return_to: returnTo }).toString()
                : undefined,
            }}
          >
            Sign up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
