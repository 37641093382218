import "core-js/stable";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store";

let element = document.getElementById("app");

if (!element) {
  element = document.createElement("main");
  element.id = "app";
  document.body.appendChild(element);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  element
);
