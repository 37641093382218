import { TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { useState } from "react";
import Main from "../main/Main";
import { UsagePeriod } from "./slice";
import UsagePeriodChart from "./UsagePeriodChart";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const Usage = () => {
  const [value, setValue] = useState(UsagePeriod.Day);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: UsagePeriod
  ) => {
    setValue(newValue);
  };

  return (
    <Main>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Usage</Typography>
      </Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            value={value}
            onChange={handleChange}
            aria-label="usage for period"
          >
            <Tab label="Past hour" value={UsagePeriod.Hour} />
            <Tab label="Past day" value={UsagePeriod.Day} />
            <Tab label="Past week" value={UsagePeriod.Week} />
            <Tab label="Past month" value={UsagePeriod.Month} />
          </TabList>
        </Box>
        <TabPanel value={UsagePeriod.Hour}>
          <UsagePeriodChart period={UsagePeriod.Hour} />
        </TabPanel>
        <TabPanel value={UsagePeriod.Day}>
          <UsagePeriodChart period={UsagePeriod.Day} />
        </TabPanel>
        <TabPanel value={UsagePeriod.Week}>
          <UsagePeriodChart period={UsagePeriod.Week} />
        </TabPanel>
        <TabPanel value={UsagePeriod.Month}>
          <UsagePeriodChart period={UsagePeriod.Month} />
        </TabPanel>
      </TabContext>
    </Main>
  );
};

export default Usage;
