// http://127.0.0.1:4455/error?id=a712ebbe-4e1c-4c15-ad09-f5b32ae7221f
import {
  Alert,
  CircularProgress,
  Container,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getError, selectError, selectErrorError } from "./slice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Box } from "@mui/system";

const Error = () => {
  const [query, setQuery] = useSearchParams();
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const errorError = useAppSelector(selectErrorError);
  const errorId = query.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    if (errorId) {
      dispatch(getError({ errorId }));
    } else {
      navigate("/login");
    }
  }, [errorId]);

  useEffect(() => {
    if (!errorError) {
      return;
    }

    switch (errorError.responseStatus) {
      case 404:
      case 403:
      case 410: {
        window.location.href = DEFAULT_REDIRECT_URL;
        break;
      }
    }
  }, [errorError]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {error ? (
        <Paper elevation={4} sx={{ m: 2, p: 2, flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {(error.error as any)?.status ?? "Error"}
          </Typography>

          <Alert severity="error">
            {(error.error as any)?.message ?? "Unknown error"}
          </Alert>
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ px: 2, pt: 1 }}>
        <Typography>
          <Link to="/login" component={RouterLink}>
            Login
          </Link>
        </Typography>
      </Box>

      <Box sx={{ px: 2, pt: 1 }}>
        <Typography>
          <Link to="/registration" component={RouterLink}>
            Sign up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Error;
