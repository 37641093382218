import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface DataWrapper<T> {
  data: T;
}

export interface UsageStatistics {
  bucketTimestamp: string;
  identityId: string;
  tcpByteCount: number;
  httpByteCount: number;
}

interface GetUsageStatisticsResponse {
  sinceTimestamp: string;
  untilTimestamp: string;
  usageStatistics: UsageStatistics[];
}

export enum UsagePeriod {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
}

export const usageApi = createApi({
  reducerPath: "usageApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL, credentials: "include" }),
  tagTypes: ["usage"],
  endpoints: (builder) => ({
    getUsageForPeriod: builder.query<GetUsageStatisticsResponse, UsagePeriod>({
      query: (period) => ({ url: "usage", params: { period } }),
      providesTags: ["usage"],
      transformResponse: (response: DataWrapper<GetUsageStatisticsResponse>) =>
        response.data,
    }),
  }),
});

export const { useGetUsageForPeriodQuery } = usageApi;
