import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface DataWrapper<T> {
  data: T;
}

interface AuthenticationToken {
  id: string;
  createdAt: string;
  updatedAt: string;
  identityId: string;
  value: string;
}

export const tokensApi = createApi({
  reducerPath: "tokensApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL, credentials: "include" }),
  tagTypes: ["tokens"],
  endpoints: (builder) => ({
    getTokens: builder.query<AuthenticationToken[], void>({
      query: () => "tokens",
      providesTags: ["tokens"],
      transformResponse: (response: DataWrapper<AuthenticationToken[]>) =>
        response.data,
    }),

    createToken: builder.mutation<
      AuthenticationToken,
      { unclaimedSessionKey: string } | null
    >({
      query: (data) => ({
        url: "tokens",
        method: "POST",
        body: { data },
      }),
      invalidatesTags: ["tokens"],
      transformResponse: (response: DataWrapper<AuthenticationToken>) =>
        response.data,
    }),

    deleteToken: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `tokens/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tokens"],
    }),
  }),
});

export const {
  useGetTokensQuery,
  useCreateTokenMutation,
  useDeleteTokenMutation,
} = tokensApi;
