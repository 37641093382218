import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { mainSlice } from "./main/slice";
import { tokensApi } from "./tokens/slice";
import { usageApi } from "./usage/slice";
import { userSlice } from "./user/slice";

export const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [tokensApi.reducerPath]: tokensApi.reducer,
    [mainSlice.name]: mainSlice.reducer,
    [usageApi.reducerPath]: usageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tokensApi.middleware, usageApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
