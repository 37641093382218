import { FlowError } from "./slice";

const handleFlowError = ({
  resetFlow,
  setError,
  error,
}: {
  resetFlow: () => void;
  setError: (error: string) => void;
  error: FlowError | undefined;
}) => {
  if (!error) {
    return;
  }

  switch (error.errorId) {
    case "session_aal2_required": {
      window.location.href = error.redirectBrowserTo ?? DEFAULT_REDIRECT_URL;
      return;
    }

    case "session_already_available": {
      window.location.href = DEFAULT_REDIRECT_URL;
      return;
    }

    case "session_refresh_required": {
      window.location.href = error.redirectBrowserTo ?? DEFAULT_REDIRECT_URL;
      return;
    }

    case "self_service_flow_return_to_forbidden": {
      setError("The return_to address is not allowed.");
      resetFlow();
      return;
    }

    case "self_service_flow_expired": {
      setError("Your interaction expired, please fill out the form again.");
      resetFlow();
      return;
    }

    case "security_csrf_violation": {
      setError(
        "A security violation was detected, please fill out the form again."
      );
      resetFlow();
      return;
    }

    case "security_identity_mismatch": {
      resetFlow();
      return;
    }

    case "browser_location_change_required": {
      window.location.href = error.redirectBrowserTo ?? DEFAULT_REDIRECT_URL;
      return;
    }

    default: {
      break;
    }
  }

  switch (error.responseStatus) {
    case 404:
      resetFlow();
      return;

    case 410:
      // The flow expired
      resetFlow();
      return;

    default: {
      break;
    }
  }
};

export default handleFlowError;
