import { useMediaQuery } from "@mui/material";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { selectColorMode } from "./main/slice";
import type { RootState, AppDispatch } from "./store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useColorMode = () => {
  const mode = useAppSelector(selectColorMode);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  return mode ?? (prefersDarkMode ? "dark" : "light");
};
