import { CircularProgress, Container } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getIdentity, selectIsIdentityFetched } from "./slice";

const UserContext = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const isIdentityFetched = useAppSelector(selectIsIdentityFetched);

  useEffect(() => {
    dispatch(getIdentity());
  }, []);

  if (!isIdentityFetched) {
    return (
      <Container
        sx={{
          pt: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return <div>{children}</div>;
};

export default UserContext;
