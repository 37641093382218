import {
  Alert,
  AlertColor,
  SxProps,
  TextField,
  Theme,
  buttonClasses,
  Box,
} from "@mui/material";
import { UiContainer, UiNode, UiNodeInputAttributes } from "@ory/kratos-client";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import capitalize from "lodash/capitalize";
import GoogleLogo from "../images/google.svg";
import { styled } from "@mui/system";

const GoogleButton = styled(LoadingButton)`
  font-family: "Roboto", sans-serif;
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 0;
  padding-right: 16px;

  &:hover {
    background-color: #4285f4;
  }

  & .${buttonClasses.startIcon} {
    margin-left: 0;
    margin-right: 16px;
  }
`;

type UiType =
  | "registration"
  | "verification"
  | "login"
  | "recovery"
  | "settings";

const FlowSubmitButton = ({
  node,
  sx,
  isSubmitting,
}: {
  node: Omit<UiNode, "attributes"> & { attributes: UiNodeInputAttributes };
  sx?: SxProps<Theme>;
  isSubmitting: boolean;
}) => {
  if (
    node.group === "oidc" &&
    node.attributes.name === "provider" &&
    node.attributes.value === "google"
  ) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <GoogleButton
          sx={sx as any}
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          disabled={node.attributes.disabled}
          loading={isSubmitting}
          startIcon={<img src={GoogleLogo} alt="Google logo" />}
        >
          {node.meta.label?.text ?? "Sign up"}
        </GoogleButton>
      </Box>
    );
  }

  return (
    <LoadingButton
      sx={sx}
      fullWidth
      type="submit"
      size="large"
      variant="contained"
      color="secondary"
      disabled={node.attributes.disabled}
      loading={isSubmitting}
    >
      {node.meta.label?.text ?? "Sign up"}
    </LoadingButton>
  );
};

const FlowInputNode = ({
  node,
  sx,
  type,
  isSubmitting,
}: {
  node: Omit<UiNode, "attributes"> & { attributes: UiNodeInputAttributes };
  sx?: SxProps<Theme>;
  type: UiType;
  isSubmitting: boolean;
}) => {
  switch (node.attributes.type) {
    case "hidden": {
      return (
        <input
          type="hidden"
          name={node.attributes.name}
          value={node.attributes.value}
        />
      );
    }

    case "text":
    case "password":
    case "email": {
      const hasError = node.messages.some(
        (message) => message.type === "error"
      );

      let autoComplete = undefined;
      if (node.attributes.type === "password" && type === "registration") {
        autoComplete = "new-password";
      }

      return (
        <TextField
          sx={sx}
          fullWidth
          error={hasError}
          type={node.attributes.type}
          name={node.attributes.name}
          required={node.attributes.required}
          disabled={node.attributes.disabled}
          label={node.meta.label?.text ?? capitalize(node.attributes.name)}
          hiddenLabel={node.meta.label === undefined}
          id={node.attributes.name}
          variant="outlined"
          autoComplete={autoComplete}
          helperText={node.messages.map((m) => m.text).join("\n")}
          defaultValue={node.attributes.value}
        />
      );
    }

    case "submit": {
      return (
        <>
          <input
            type="hidden"
            name={node.attributes.name}
            value={node.attributes.value}
          />
          <FlowSubmitButton node={node} isSubmitting={isSubmitting} sx={sx} />
        </>
      );
    }

    default: {
      return <div>{JSON.stringify(node)}</div>;
    }
  }
};

const FlowUiNode = ({
  node,
  sx,
  type,
  isSubmitting,
}: {
  node: UiNode;
  sx?: SxProps<Theme>;
  type: UiType;
  isSubmitting: boolean;
}) => {
  switch (node.attributes.node_type) {
    case "input": {
      const attributes = node.attributes as UiNodeInputAttributes;
      return (
        <FlowInputNode
          node={{ ...node, attributes }}
          sx={sx}
          type={type}
          isSubmitting={isSubmitting}
        />
      );
    }

    default: {
      return <div>{JSON.stringify(node)}</div>;
    }
  }
};

const FlowUi = ({
  ui,
  onSubmit,
  type,
  filterGroup,
  hideGlobalMessage,
}: {
  ui: UiContainer;
  onSubmit: (formData: Record<string, FormDataEntryValue>) => Promise<void>;
  type: UiType;
  filterGroup?: string;
  hideGlobalMessage?: boolean;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <form
      action={ui.action}
      method={ui.method}
      onSubmit={(event) => {
        setIsSubmitting(true);
        event.preventDefault();
        onSubmit(
          Object.fromEntries(
            new FormData(event.target as HTMLFormElement).entries()
          )
        ).finally(() => setIsSubmitting(false));
      }}
    >
      {ui.nodes
        .filter((node) => {
          if (filterGroup) {
            // With filter always include default group + the target group
            return node.group === "default" || node.group === filterGroup;
          }

          // Otherwise allow all
          return true;
        })
        .map((node, index) => (
          <FlowUiNode
            key={index}
            node={node}
            sx={{ mt: index > 0 ? 2 : 0 }}
            type={type}
            isSubmitting={isSubmitting}
          />
        ))}

      {!hideGlobalMessage &&
        ui.messages &&
        ui.messages.map((message) => (
          <Alert
            key={message.id}
            severity={message.type as AlertColor}
            sx={{ mt: 2 }}
          >
            {message.text}
          </Alert>
        ))}
    </form>
  );
};

export default FlowUi;
