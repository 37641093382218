import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SubmitSelfServiceVerificationFlowBody } from "@ory/kratos-client";
import FlowUi from "./FlowUi";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  getOrCreateVerificationFlow,
  selectVerificationFlow,
  selectVerificationFlowError,
  submitVerificationFlow,
} from "./slice";
import handleFlowError from "./handleFlowError";

const Verification = () => {
  const [query, setQuery] = useSearchParams();
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectVerificationFlow);
  const flowError = useAppSelector(selectVerificationFlowError);
  const flowId = query.get("flow");
  const returnTo = query.get("return_to");
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(getOrCreateVerificationFlow({ flowId, returnTo }));
  }, []);

  useEffect(() => {
    const newQuery = new URLSearchParams();

    if (returnTo) {
      newQuery.set("return_to", returnTo);
    }

    setQuery(newQuery, { replace: true });
  }, [flow]);

  useEffect(() => {
    handleFlowError({
      resetFlow: () =>
        dispatch(getOrCreateVerificationFlow({ flowId: null, returnTo })),
      setError,
      error: flowError,
    });
  }, [flowError]);

  const onSubmit = async (data: unknown) => {
    if (!flow) {
      return;
    }

    setError(undefined);

    const newQuery = new URLSearchParams();
    newQuery.set("flow", flow.id);
    setQuery(newQuery, { replace: true });

    try {
      await dispatch(
        submitVerificationFlow({
          flowId: flow.id,
          body: data as SubmitSelfServiceVerificationFlowBody,
        })
      ).unwrap();
    } catch {
      // await is just for progress and error is handled in redux
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: 4,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {flow ? (
        <Paper elevation={4} sx={{ m: 2, p: 2, flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Request a new verification email
          </Typography>

          {error && <Alert severity="error">{error}</Alert>}

          <FlowUi type="verification" ui={flow.ui} onSubmit={onSubmit} />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Verification;
