import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  UsagePeriod,
  UsageStatistics,
  useGetUsageForPeriodQuery,
} from "./slice";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import { DateTime } from "luxon";

const formatMillisTime = (period: UsagePeriod, millis: number) => {
  switch (period) {
    case UsagePeriod.Hour:
    case UsagePeriod.Day: {
      return DateTime.fromMillis(millis).toLocaleString(DateTime.TIME_SIMPLE);
    }

    case UsagePeriod.Week:
    case UsagePeriod.Month: {
      return DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_SHORT);
    }

    default: {
      return DateTime.fromMillis(millis).toLocaleString(DateTime.TIME_SIMPLE);
    }
  }
};

const bytesToMegaBytes = (bytes: number) => bytes / (1000 * 1000);

const CustomTooltip = ({
  active,
  payload,
  label,
  period,
}: {
  period: UsagePeriod;
  active?: boolean;
  label?: number;
  payload?: Array<{ name: string; value: number; color: string }>;
}) => {
  if (active && payload && payload.length > 0 && label) {
    return (
      <Paper sx={{ p: 1 }}>
        <Typography sx={{ mb: 1 }}>
          {formatMillisTime(period, label)}
        </Typography>
        {payload.map((p) => (
          <Box key={p.name}>
            <Typography component="span" color={p.color}>
              {p.name}: {bytesToMegaBytes(p.value)}MB
            </Typography>
          </Box>
        ))}
        <Box>
          <Typography component="span">Total:</Typography>{" "}
          <Typography component="span">
            {bytesToMegaBytes(
              payload.map((p) => p.value).reduce((a, b) => a + b, 0)
            )}
            MB
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

const UsagePeriodChart = ({ period }: { period: UsagePeriod }) => {
  const theme = useTheme();
  const { data, error, isLoading } = useGetUsageForPeriodQuery(period, {
    pollingInterval: 60 * 1000,
  });

  if (isLoading || !data) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return <Alert severity="error">Error fetching usage</Alert>;
  }

  if (data.usageStatistics.length === 0) {
    return <Alert severity="info">No usage during selected period</Alert>;
  }

  return (
    <Box sx={{ height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data.usageStatistics}
          margin={{ top: 20, right: 0, left: 20, bottom: 40 }}
        >
          <XAxis
            type="number"
            padding={{ left: 20, right: 20 }}
            domain={[
              DateTime.fromISO(data.sinceTimestamp).toMillis(),
              DateTime.fromISO(data.untilTimestamp).toMillis(),
            ]}
            dataKey={(item: UsageStatistics) =>
              DateTime.fromISO(item.bucketTimestamp).toMillis()
            }
            tickFormatter={(millis) => formatMillisTime(period, millis)}
            stroke={theme.palette.text.primary}
          />
          <YAxis
            type="number"
            unit="MB"
            tickFormatter={(item) => `${bytesToMegaBytes(item)}`}
            stroke={theme.palette.text.primary}
          />
          <Tooltip content={<CustomTooltip period={period} />} />
          <Legend wrapperStyle={{ position: "relative" }} />
          <Bar
            maxBarSize={30}
            name="HTTP"
            dataKey="httpByteCount"
            fill={theme.palette.secondary.light}
            stackId="a"
          />
          <Bar
            maxBarSize={30}
            name="TCP"
            dataKey="tcpByteCount"
            fill={theme.palette.primary.light}
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default UsagePeriodChart;
