import {
  Configuration,
  SelfServiceRegistrationFlow,
  SubmitSelfServiceRegistrationFlowBody,
  V0alpha2Api,
  Identity,
  SuccessfulSelfServiceRegistrationWithoutBrowser,
  SelfServiceVerificationFlow,
  SubmitSelfServiceVerificationFlowBody,
  SelfServiceLoginFlow,
  SubmitSelfServiceLoginFlowBody,
  SuccessfulSelfServiceLoginWithoutBrowser,
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowBody,
  SelfServiceSettingsFlow,
  SubmitSelfServiceSettingsFlowBody,
  SelfServiceError,
} from "@ory/kratos-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "../store";

const api = new V0alpha2Api(
  new Configuration({
    basePath: KRATOS_BASE_URL,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export interface FlowError {
  responseStatus?: number;
  errorId?: string;
  redirectBrowserTo?: string;
}

export const logout = createAsyncThunk("user/logout", async () => {
  const response = await api.createSelfServiceLogoutFlowUrlForBrowsers();
  window.location.href = response.data.logout_url;
});

export const getIdentity = createAsyncThunk<Identity>(
  "user/getIdentity",
  async () => {
    const response = await api.toSession();
    return response.data.identity;
  }
);

export const getOrCreateLoginFlow = createAsyncThunk<
  SelfServiceLoginFlow,
  { flowId: string | null; returnTo: string | null }
>(
  "user/getOrCreateLoginFlow",
  async ({ flowId, returnTo }, { rejectWithValue }) => {
    try {
      if (flowId) {
        const response = await api.getSelfServiceLoginFlow(flowId);
        return response.data;
      } else {
        const response = await api.initializeSelfServiceLoginFlowForBrowsers(
          undefined,
          undefined,
          returnTo ?? undefined
        );

        return response.data;
      }
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const submitLoginFlow = createAsyncThunk<
  SuccessfulSelfServiceLoginWithoutBrowser,
  {
    flowId: string;
    body: SubmitSelfServiceLoginFlowBody;
  }
>(
  "user/submitLoginFlow",
  async ({ flowId, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.submitSelfServiceLoginFlow(
        flowId,
        undefined,
        body
      );
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          dispatch(setLoginFlow(axiosError.response.data));
          throw error;
        }

        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const getOrCreateRegistrationFlow = createAsyncThunk<
  SelfServiceRegistrationFlow,
  { flowId: string | null; returnTo: string | null }
>(
  "user/getOrCreateRegistrationFlow",
  async ({ flowId, returnTo }, { rejectWithValue }) => {
    try {
      if (flowId) {
        const response = await api.getSelfServiceRegistrationFlow(flowId);
        return response.data;
      } else {
        const response =
          await api.initializeSelfServiceRegistrationFlowForBrowsers(
            undefined,
            {
              params: {
                after_verification_return_to: returnTo,
              },
            }
          );

        return response.data;
      }
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const submitRegistrationFlow = createAsyncThunk<
  SuccessfulSelfServiceRegistrationWithoutBrowser,
  {
    flowId: string;
    body: SubmitSelfServiceRegistrationFlowBody;
  }
>(
  "user/submitRegistrationFlow",
  async ({ flowId, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.submitSelfServiceRegistrationFlow(
        flowId,
        body
      );
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          dispatch(setRegistrationFlow(axiosError.response.data));
          throw error;
        }

        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const getOrCreateVerificationFlow = createAsyncThunk<
  SelfServiceVerificationFlow,
  { flowId: string | null; returnTo: string | null }
>(
  "user/getOrCreateVerificationFlow",
  async ({ flowId, returnTo }, { rejectWithValue }) => {
    try {
      if (flowId) {
        const response = await api.getSelfServiceVerificationFlow(flowId);
        return response.data;
      } else {
        const response =
          await api.initializeSelfServiceVerificationFlowForBrowsers(
            returnTo ?? undefined
          );
        return response.data;
      }
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const submitVerificationFlow = createAsyncThunk<
  SelfServiceVerificationFlow,
  {
    flowId: string;
    body: SubmitSelfServiceVerificationFlowBody;
  }
>(
  "user/submitVerificationFlow",
  async ({ flowId, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.submitSelfServiceVerificationFlow(
        flowId,
        undefined,
        body
      );
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          dispatch(setVerificationFlow(axiosError.response.data));
          throw error;
        }

        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const getOrCreateRecoveryFlow = createAsyncThunk<
  SelfServiceRecoveryFlow,
  { flowId: string | null; returnTo: string | null }
>(
  "user/getOrCreateRecoveryFlow",
  async ({ flowId, returnTo }, { rejectWithValue }) => {
    try {
      if (flowId) {
        const response = await api.getSelfServiceRecoveryFlow(flowId);
        return response.data;
      } else {
        const response = await api.initializeSelfServiceRecoveryFlowForBrowsers(
          returnTo ?? undefined
        );
        return response.data;
      }
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const submitRecoveryFlow = createAsyncThunk<
  SelfServiceRecoveryFlow,
  {
    flowId: string;
    body: SubmitSelfServiceRecoveryFlowBody;
  }
>(
  "user/submitRecoveryFlow",
  async ({ flowId, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.submitSelfServiceRecoveryFlow(
        flowId,
        undefined,
        body
      );
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          dispatch(setRecoveryFlow(axiosError.response.data));
          throw error;
        }

        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const getOrCreateSettingsFlow = createAsyncThunk<
  SelfServiceSettingsFlow,
  { flowId: string | null; returnTo: string | null }
>(
  "user/getOrCreateSettingsFlow",
  async ({ flowId, returnTo }, { rejectWithValue }) => {
    try {
      if (flowId) {
        const response = await api.getSelfServiceSettingsFlow(flowId);
        return response.data;
      } else {
        const response = await api.initializeSelfServiceSettingsFlowForBrowsers(
          returnTo ?? undefined
        );
        return response.data;
      }
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const submitSettingsFlow = createAsyncThunk<
  SelfServiceSettingsFlow,
  {
    flowId: string;
    body: SubmitSelfServiceSettingsFlowBody;
  }
>(
  "user/submitSettingsFlow",
  async ({ flowId, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.submitSelfServiceSettingsFlow(
        flowId,
        undefined,
        body
      );
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          dispatch(setSettingsFlow(axiosError.response.data));
          throw error;
        }

        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

export const getError = createAsyncThunk<SelfServiceError, { errorId: string }>(
  "user/getError",
  async ({ errorId }, { rejectWithValue }) => {
    try {
      const response = await api.getSelfServiceError(errorId);
      return response.data;
    } catch (error) {
      if ((error as any).isAxiosError) {
        const axiosError = error as AxiosError;
        return rejectWithValue({
          responseStatus: axiosError.response?.status,
          errorId: axiosError.response?.data.error?.id,
          redirectBrowserTo: axiosError.response?.data.redirect_browser_to,
        });
      }

      throw error;
    }
  }
);

interface UserState {
  identity: Identity | undefined;
  isIdentityFetched: boolean;
  loginFlow: SelfServiceLoginFlow | undefined;
  loginFlowError: FlowError | undefined;
  registrationFlow: SelfServiceRegistrationFlow | undefined;
  registrationFlowError: FlowError | undefined;
  verificationFlow: SelfServiceVerificationFlow | undefined;
  verificationFlowError: FlowError | undefined;
  recoveryFlow: SelfServiceRecoveryFlow | undefined;
  recoveryFlowError: FlowError | undefined;
  settingsFlow: SelfServiceSettingsFlow | undefined;
  settingsFlowError: FlowError | undefined;
  error: SelfServiceError | undefined;
  errorError: FlowError | undefined;
}

const initialState: UserState = {
  identity: undefined,
  isIdentityFetched: false,
  loginFlow: undefined,
  loginFlowError: undefined,
  registrationFlow: undefined,
  registrationFlowError: undefined,
  verificationFlow: undefined,
  verificationFlowError: undefined,
  recoveryFlow: undefined,
  recoveryFlowError: undefined,
  settingsFlow: undefined,
  settingsFlowError: undefined,
  error: undefined,
  errorError: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginFlow: (state, { payload }: PayloadAction<SelfServiceLoginFlow>) => {
      state.loginFlow = payload;
    },
    setRegistrationFlow: (
      state,
      { payload }: PayloadAction<SelfServiceRegistrationFlow>
    ) => {
      state.registrationFlow = payload;
    },
    setVerificationFlow: (
      state,
      { payload }: PayloadAction<SelfServiceVerificationFlow>
    ) => {
      state.verificationFlow = payload;
    },
    setRecoveryFlow: (
      state,
      { payload }: PayloadAction<SelfServiceRecoveryFlow>
    ) => {
      state.recoveryFlow = payload;
    },
    setSettingsFlow: (
      state,
      { payload }: PayloadAction<SelfServiceSettingsFlow>
    ) => {
      state.settingsFlow = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.fulfilled, (state, { payload }) => {
        state.identity = payload;
        state.isIdentityFetched = true;
      })
      .addCase(getIdentity.rejected, (state) => {
        state.isIdentityFetched = true;
      })
      .addCase(getOrCreateLoginFlow.fulfilled, (state, { payload }) => {
        state.loginFlow = payload;
      })
      .addCase(getOrCreateLoginFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.loginFlowError = payload as FlowError;
        }
      })
      .addCase(submitLoginFlow.fulfilled, (state, { payload }) => {
        state.identity = payload.session.identity;

        if (state.loginFlow?.return_to) {
          window.location.href = state.loginFlow?.return_to;
        } else {
          window.location.href = DEFAULT_REDIRECT_URL;
        }
      })
      .addCase(submitLoginFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.loginFlowError = payload as FlowError;
        }
      })
      .addCase(getOrCreateRegistrationFlow.fulfilled, (state, { payload }) => {
        state.registrationFlow = payload;
      })
      .addCase(getOrCreateRegistrationFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.registrationFlowError = payload as FlowError;
        }
      })
      .addCase(submitRegistrationFlow.fulfilled, (state, { payload }) => {
        state.identity = payload.identity;

        if (state.registrationFlow?.return_to) {
          window.location.href = state.registrationFlow?.return_to;
        } else {
          window.location.href = DEFAULT_REDIRECT_URL;
        }
      })
      .addCase(submitRegistrationFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.registrationFlowError = payload as FlowError;
        }
      })
      .addCase(getOrCreateVerificationFlow.fulfilled, (state, { payload }) => {
        state.verificationFlow = payload;
      })
      .addCase(getOrCreateVerificationFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.verificationFlowError = payload as FlowError;
        }
      })
      .addCase(submitVerificationFlow.fulfilled, (state, { payload }) => {
        state.verificationFlow = payload;
      })
      .addCase(submitVerificationFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.verificationFlowError = payload as FlowError;
        }
      })
      .addCase(getOrCreateRecoveryFlow.fulfilled, (state, { payload }) => {
        state.recoveryFlow = payload;
      })
      .addCase(getOrCreateRecoveryFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.recoveryFlowError = payload as FlowError;
        }
      })
      .addCase(submitRecoveryFlow.fulfilled, (state, { payload }) => {
        state.recoveryFlow = payload;
      })
      .addCase(submitRecoveryFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.recoveryFlowError = payload as FlowError;
        }
      })
      .addCase(getOrCreateSettingsFlow.fulfilled, (state, { payload }) => {
        state.settingsFlow = payload;
      })
      .addCase(getOrCreateSettingsFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.settingsFlowError = payload as FlowError;
        }
      })
      .addCase(submitSettingsFlow.fulfilled, (state, { payload }) => {
        state.identity = payload.identity;
        state.settingsFlow = payload;
      })
      .addCase(submitSettingsFlow.rejected, (state, { payload }) => {
        if (payload) {
          state.settingsFlowError = payload as FlowError;
        }
      })
      .addCase(getError.fulfilled, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getError.rejected, (state, { payload }) => {
        if (payload) {
          state.errorError = payload as FlowError;
        }
      });
  },
});

export const {
  setLoginFlow,
  setRegistrationFlow,
  setVerificationFlow,
  setRecoveryFlow,
  setSettingsFlow,
} = userSlice.actions;

export const selectIsIdentityFetched = (state: RootState) =>
  state.user.isIdentityFetched;

export const selectIdentity = (state: RootState) => state.user.identity;

export const selectLoginFlow = (state: RootState) => state.user.loginFlow;

export const selectLoginFlowError = (state: RootState) =>
  state.user.loginFlowError;

export const selectRegistrationFlow = (state: RootState) =>
  state.user.registrationFlow;

export const selectRegistrationFlowError = (state: RootState) =>
  state.user.registrationFlowError;

export const selectVerificationFlow = (state: RootState) =>
  state.user.verificationFlow;

export const selectVerificationFlowError = (state: RootState) =>
  state.user.verificationFlowError;

export const selectRecoveryFlow = (state: RootState) => state.user.recoveryFlow;

export const selectRecoveryFlowError = (state: RootState) =>
  state.user.recoveryFlowError;

export const selectSettingsFlow = (state: RootState) => state.user.settingsFlow;

export const selectSettingsFlowError = (state: RootState) =>
  state.user.settingsFlowError;

export const selectError = (state: RootState) => state.user.error;

export const selectErrorError = (state: RootState) => state.user.errorError;
