import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Tokens from "./tokens/Tokens";
import VerificationRequired from "./main/VerificationRequired";
import Login from "./user/Login";
import Registration from "./user/Registration";
import RequireUser from "./user/RequireUser";
import UserContext from "./user/UserContext";
import Verification from "./user/Verification";
import Usage from "./usage/Usage";
import Recovery from "./user/Recovery";
import Settings from "./user/Settings";
import Error from "./user/Error";

const Router = () => (
  <UserContext>
    <BrowserRouter>
      <Routes>
        <Route
          path="/usage"
          element={
            <RequireUser>
              <Usage />
            </RequireUser>
          }
        />
        <Route
          path="/tokens"
          element={
            <RequireUser>
              <Tokens />
            </RequireUser>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireUser>
              <Settings />
            </RequireUser>
          }
        />
        <Route
          path="/verification_required"
          element={<VerificationRequired />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Navigate to="/usage" replace />} />
      </Routes>
    </BrowserRouter>
  </UserContext>
);

export default Router;
